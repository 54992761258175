<template>
  <div>
    <RankUsersComponent
      v-for="rank in ranks"
      :key="rank.name"
      :title="rank.title"
      :api="rank.api"
      :name="rank.name"
      :list-url="rank.listUrl"
    />
  </div>
</template>

<script>
  import RankUsersComponent from '../components/RankUsersComponent'
  import i18n from '@/i18n'

  const ranks = [
    {
      title: i18n.t('rank.answer', [i18n.t('date.today')]),
      listUrl: '/rank/q/today',
      api: '/question-ranks/answers?type=day&limit=15',
      name: 'rankQuestionsToday',
    },
    {
      title: i18n.t('rank.answer', [i18n.t('date.week')]),
      listUrl: '/rank/q/week',
      api: '/question-ranks/answers?type=week&limit=15',
      name: 'rankQuestionsWeek',
    },
    {
      title: i18n.t('rank.answer', [i18n.t('date.month')]),
      listUrl: '/rank/q/month',
      api: '/question-ranks/answers?type=month&limit=15',
      name: 'rankQuestionsMonth',
    },
  ]

  export default {
    name: 'RankQuestions',
    components: {
      RankUsersComponent,
    },
    data () {
      return {
        ranks,
      }
    },
  }
</script>
