<template>
  <div>
    <template v-for="rank in ranks">
      <RankUsersComponent
        v-if="rank.name !== 'rankCheckin' || checkinEnable"
        :key="rank.name"
        :title="rank.title"
        :api="rank.api"
        :name="rank.name"
        :list-url="rank.listUrl"
      />
    </template>
  </div>
</template>

<script>
  import RankUsersComponent from '../components/RankUsersComponent'
  import i18n from '@/i18n'

  const ranks = [
    {
      title: i18n.t('rank.fans'),
      listUrl: '/rank/users/followers',
      api: '/ranks/followers?limit=15',
      name: 'rankFollowers',
    },
    {
      title: i18n.t('rank.balance'),
      listUrl: '/rank/users/balance',
      api: '/ranks/balance?limit=15',
      name: 'rankBalance',
    },
    {
      title: i18n.t('rank.income'),
      listUrl: '/rank/users/income',
      api: '/ranks/income?limit=15',
      name: 'rankIncome',
    },
    {
      title: i18n.t('rank.checkin'),
      listUrl: '/rank/users/checkin',
      api: '/checkin-ranks?limit=15',
      name: 'rankCheckin',
    },
    {
      title: i18n.t('rank.expert'),
      listUrl: '/rank/users/question-experts',
      api: '/question-ranks/experts?limit=15',
      name: 'rankQuestionExperts',
    },
    {
      title: i18n.t('rank.question_like'),
      listUrl: '/rank/users/question-likes',
      api: '/question-ranks/likes?limit=15',
      name: 'rankQuestionLikes',
    },
  ]

  export default {
    name: 'RankUsers',
    components: {
      RankUsersComponent,
    },
    data () {
      return {
        ranks,
      }
    },
    computed: {
      checkinEnable () {
        const { checkin } = this.$store.state.CONFIG || {}
        return checkin.switch
      },
    },
  }
</script>
